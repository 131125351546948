/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-footer {
  margin-top: 20px;
  text-align: right;
}

.modal-footer button {
  padding: 8px 16px;
  font-size: 16px;
}
