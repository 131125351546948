/* src/App.module.css */

body {
  font-family: Arial, sans-serif;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: small;
}

tr:nth-of-type(even) > td {
  background-color: #f2f2f2;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #5a4099;
  color: white;
}

.homeworkCell {
  background-color: #ffffcc !important;
  cursor: pointer;
}

.timetableCell {
  cursor: pointer;
}

.timetableCell:hover {
  background-color: #d8c9fd;
}

input[type="text"] {
  width: 100%;
}

.tblGrid {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

h2 {
  color: #333;
  font-size: small;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 300px;
}

.header {
  background-color: #5a4099;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  display: block;
  width: 100%;
  height: 60px;
  background-image: url("./images/PS.png");
  background-repeat: no-repeat;
  background-position: 5px 5px;
}
